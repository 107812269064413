
import { Options, Vue } from "vue-class-component";
import Watts4SatsService from "@/services/Watts4SatsService";
import Voucher from "@/types/Voucher";
import Invoice from "@/types/Invoice";
import QrcodeVue from "qrcode.vue";
import moment from "moment";

@Options({
  components: {
    QrcodeVue,
  },
})
export default class Vouchers extends Vue {
  private amount: number | null = null;
  private meterNumber = "";
  private invoice: Invoice | null = null;
  private vouchers: Voucher[] = [];
  private errors: string[] = [];

  async mounted() {
    this.meterNumber = localStorage.getItem("watts4sats.meterNumber") || "";
    this.updateProfile(true);
    setInterval(this.updateProfile, 2000);
  }

  // writeToClipboard(test: any) {
  //   if (!this.invoice) return;

  //   navigator.clipboard.writeText(this.invoice.PaymentRequest).then(function() {
  //     alert('done!');
  //   }, function() {
  //     alert('failed!');
  //   }).catch((e) => alert(e)).finally(() => alert('done!'));
  // }
  get lightningUri(): string {
    if (!this.invoice) return '';

    return `lightning:${this.invoice.PaymentRequest}`;
  }

  private generateInvoice(): void {
    this.errors = [];
    // Validation
    if (this.meterNumber.length < 5) {
      this.errors.push("Meter number too short");
      return;
    }
    if (this.amount === null) {
      this.errors.push("How much in fiat (Rands) do you want to spend?");
      return;
    }
    if (this.amount < 25) {
      this.errors.push("Sorry, the smallest amount you can get is R 25");
      return;
    }
    if (this.amount > 1000) {
      this.errors.push("Sorry, the biggest amount you can get is R 1000");
      return;
    }

    Watts4SatsService.generateInvoice(this.meterNumber, this.amount || 0)
      .then((response) => {
        this.invoice = response.data as Invoice;
        this.updateProfile(true);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  get showNewTransactionButton(): boolean {
    return this.invoice !== null && this.invoice.State !== 'CREATED' && this.invoice.State !== 'PAID';
  }

  private clearInvoice() {
    this.invoice = null;
  }

  get showCancelButton(): boolean {
    return this.invoice !== null && this.invoice.State === 'CREATED';
  }

  private cancelInvoice(): void {
    if (!this.invoice) return;

    Watts4SatsService.cancelInvoice(this.invoice.ID)
      .then((response) => {
        this.invoice = null;
        this.updateProfile(true);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  private updateInvoice(): void {
    if (!this.invoice) return;

    Watts4SatsService.findInvoice(this.invoice.ID)
      .then((response: any) => {
        const prevState = this.invoice?.State;
        this.invoice = response.data as Invoice;
        if (this.invoice !== null && this.invoice.State !== prevState) this.updateProfile(true);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  private updateProfile(force = false): void {
    if (this.meterNumber.length < 5) {
      this.vouchers = [];
      return;
    }
    // Store meter number so it is available on next reload
    localStorage.setItem("watts4sats.meterNumber", this.meterNumber);

    // Only make network call if needed:
    let updateInvoice = (this.invoice && (this.invoice.State === 'CREATED' || this.invoice.State === 'PAID'));
    let updateVouchers = (this.vouchers && this.vouchers.some(v => v.State === 'REQUESTED'));

    if (!force && !updateInvoice && !updateVouchers) return;

    Watts4SatsService.meterProfile(this.meterNumber)
      .then((response: any) => {
        const activeInvoice = response.data.ActiveInvoice as Invoice;
        const newVouchers = response.data.Vouchers as Voucher[];
        // Only update invoice if it's not set already or state is different
        if (activeInvoice !== null && (this.invoice === null || this.invoice.State !== activeInvoice.State)) {
          this.invoice = activeInvoice;
        } else if (activeInvoice === null && (this.invoice !== null && (this.invoice.State === 'PAID' || this.invoice.State === 'CREATED'))) {
          // active invoice is null, but the invoice might still show "paid"
          this.updateInvoice();
        }
        // Only update voucher list if something changed
        const existingStates = this.vouchers.map((voucher) => voucher.State);
        const newStates = newVouchers.map((voucher: Voucher) => voucher.State);
        if (
          force ||
          existingStates.length !== newStates.length ||
          !newStates.every(
            (value: string, index: number) => value === existingStates[index]
          )
        ) {
          this.vouchers = newVouchers;
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  private voucherDetails(voucher: Voucher): string {
    switch (voucher.State) {
      case "ISSUED":
        return this.friendlyDetails(voucher);
      case "REQUESTED":
        return "Voucher requested from provider - wait about 10 seconds";
      case "FAILED":
        return this.friendlyVoucherError(voucher.Error);
      case "TIMEDOUT":
        return "Voucher request timed out, please contact support (see FAQ)";
      default:
        return "";
    }
  }

  private friendlyDetails(voucher: Voucher): string {
    let details = `Pin: ${voucher.VoucherPin}   Units: ${parseFloat(voucher.VoucherUnits).toFixed(2)}`;
    if (voucher.FreeUnitsPin)
      details += `\nExtra Pin: ${voucher.FreeUnitsPin}   Units: ${parseFloat(voucher.FreeUnits).toFixed(2)}`;
    return details;
  }

  private friendlyVoucherError(error: string): string {
    switch (error) {
      case "DUPLICATE TRANSACTION":
        return "Payment Refunded: Same amount requested within last 5 minutes";
      case "BAD METER NUMBER":
        return "Payment Refunded: Meter number not supported";
      default:
        return error;
    }
  }

  formatDate(date: Date, fmt: string): string {
    if (!date) return "";
    return moment(String(date)).format(fmt);
  }

  get invoiceStateString(): string {
    if (!this.invoice) return "";

    switch (this.invoice.State) {
      case "CREATED":
        return "Waiting for payment";
      case "PAID":
        return "Payment received - generating voucher";
      case "SETTLED":
        return "Payment accepted - voucher issued";
      case "CANCELLED":
        return "Payment refunded - could not generate voucher";
      case "ERROR":
        return "Something went wrong with the payment";
      default:
        return "";
    }
  }
}
