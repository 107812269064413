<template>
    <div class="md:container md:mx-auto sm:py-5 sm:mx-5 h-full min-h-screen">
      <div class="navbar mb-2 shadow-lg bg-neutral text-neutral-content sm:rounded-box">
        <div class="navbar-start">
        <div class="flex-none">
          <button class="btn btn-square btn-ghost">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>

        <div class="flex-1">
          <router-link to="/">
            <span class="text-lg font-bold">
              Lightning Watts
            </span>
          </router-link>
        </div>
        </div>
        <div class="hidden px-2 mx-2 navbar-center md:flex">
          ⚡ Lighthing Fast Watts with Bitcoin Lightning ⚡
        </div>

        <div class="navbar-end">
            <router-link class="btn btn-ghost btn-sm rounded-btn" to="/about">
              About / FAQ
            </router-link>
        </div>
      </div>
      <div class="md:container md:mx-auto py-5 px-5">
        <router-view />
      </div>
  </div>
</template>

<style>
  body {
    background: #f2a900;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f27d00, #f2a900);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f27d00, #f2a900); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #4d4d4e;
  }
</style>