import Voucher from "@/types/Voucher"
import Invoice from "@/types/Invoice"
import axios, { AxiosInstance, AxiosResponse } from "axios"

const apiClient: AxiosInstance = axios.create({
  // baseURL: "http://10.0.0.250:8042/api",
  baseURL: "https://watts.cryptoconvert.co.za/api",
  headers: {
    "Content-type": "application/x-www-form-urlencoded",
  },
})

class Watts4SatsService {
  public meterProfile(meterNumber: string) {
    return apiClient.get("/profile", { params: { meterNumber: meterNumber } })
  }

  public invoicesByMeterNumber(meterNumber: string): Promise<AxiosResponse<Voucher[]>> {
    return apiClient.get<Voucher[]>("/vouchers", { params: { meterNumber: meterNumber } })
  }

  public generateInvoice(meterNumber: string, amount: number): Promise<AxiosResponse<Invoice>> {
    return apiClient.post<Invoice>("/invoices", { meterNumber: meterNumber, amount: amount })
  }

  public findInvoice(id: string): Promise<AxiosResponse<Invoice>> {
    return apiClient.get<Invoice>("/invoices", { params: { id: id } })
  }

  public cancelInvoice(id: string) {
    return apiClient.delete<Invoice>("/invoices", { params: { id: id } })
  }
}

export default new Watts4SatsService()
