
import { Options, Vue } from "vue-class-component";
import Vouchers from "@/components/Vouchers.vue"; // @ is an alias to /src

@Options({
  components: {
    Vouchers,
  },
})
export default class Home extends Vue {}
